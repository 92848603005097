const pricing = [
	{
		id: 'startup',
		title: 'Startup',
		price: '$22.95 /mo',
		banner: 'AWS',
		features: ['WordPress Control Panel', 'Server Setup', 'White Label', '40GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '2TB Premium Bandwidth', '1GB Guaranteed RAM', 'Monitoring Panel', 'SSL Certificate Included', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', 'Static Amazon IPv4', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: 'rocket',
		title: 'Rocket',
		price: '$27.95 /mo',
		banner: 'AWS',
		features: ['WordPress Control Panel', 'Server Setup', 'White Label', '60GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '3TB Premium Bandwidth', '2GB Guaranteed RAM', 'Monitoring Panel', 'SSL Certificate Included', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', 'Static Amazon IPv4', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: 'professional',
		title: 'Professional',
		price: '$39.95 /mo',
		banner: 'AWS',
		features: ['WordPress Control Panel', 'Server Setup', 'White Label', '80GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '4TB Premium Bandwidth', '4GB Guaranteed RAM', 'Monitoring Panel', 'SSL Certificate Included', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', 'Static Amazon IPv4', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: 'enterprise',
		title: 'Enterprise',
		price: '$59.95 /mo',
		banner: 'AWS',
		features: ['WordPress Control Panel', 'Server Setup', 'White Label', '160GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '5TB Premium Bandwidth', '8GB Guaranteed RAM', 'Monitoring Panel', 'SSL Certificate Included', 'Full Root Access', 'Full SSH Access', 'Secure Key Management','Firewall Options', 'Snapshot Options', 'Automated Backup Options', 'Static Amazon IPv4', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
];

export default pricing;
