const applications = [
	{
		id: 'wordpress',
		name: 'WordPress',
		icon: 'Urdu',
	},
	{
		id: 'wp-multisite',
		name: 'WP Multisite',
		icon: 'Indonesian',
	},
	{
		id: 'woocommerce',
		name: 'WooCommerce',
		icon: 'Korean',
	},
	{
		id: 'elementor',
		name: 'Elementor',
		icon: 'Perl',
	},
	{
		id: 'google-site-kit',
		name: 'Google Site Kit',
		icon: 'Photoshop',
	},
];

export default applications;
